import React from 'react';
import { Layout, Seo } from 'components/common';
import { Contact } from 'components/landing';

const Survey = () => (
	<Layout>
		<Seo />
		<Contact />
	</Layout>
);

export default Survey;
